import { gql } from "@apollo/client";
/* 
    These definitions are safe for reuse within the events definitions without worrying 
    about over-fetching data.
*/

export const allowedInboxViewDefinition = `{
    special
    group
    conversationTags
    contactTags
    agent
}`;

export const textConversationMessageDefinition = `{
    id
    body
}`;

const mediaTranscriptionsDefinition = `{
    id
    status
    text
}`;

export const partialMediaDefinition = `
    uuid
    filename
    contentType
    mediaFileUrl
    thumbnailImageUrl
    mediaFileSize
`;
export const mediaDefinition = `{${partialMediaDefinition}}`;

export const mediaConversationMessageDefinition = `{
    id
    caption
    mediaMessageType
    media {
      ${partialMediaDefinition}
      transcriptions ${mediaTranscriptionsDefinition}
    }
}`;

export const locationConversationMessageDefinition = `{
    id
    latitude
    longitude
    name
    address
}`;

export const whatsappHsmConversationMessageDefinition = `{
    id
    headerType
    headerText
    headerMedia ${mediaDefinition}
    body
    footer
    whatsappHsmTranslatedTemplate {
        id
        buttons {
            id
            label
        }
    }
}`;

export const textConversationNoteDefinition = `{
    id
    body
}`;

export const mediaConversationNoteDefinition = `{
    id
    caption
    mediaMessageType
    media ${mediaDefinition}
}`;

export const locationConversationNoteDefinition = `{
    id
    latitude
    longitude
    name
    address
}`;

export const agentDefinition = `{
    id
    nickname
    user {
        firstName
        lastName
        email    
    }
}`;

export const groupDefinition = `{
    id
    name
}`;

export const contactDefinition = `{
    id
    fullName
    phoneNumber
    displayName
}`;

export const voiceProviderContactDefinition = `{
    id
    accountId
    contact ${contactDefinition}
}`;

export const voiceProviderAccountDefinition = `{
    id
    name
    accountId
}`;

export const messagingProviderContactDefinition = `{
    id
    contact ${contactDefinition}
}`;

export const contentTypeAllowedTagDefinition = `{
    id
    tag {
        id
        name
    }
}`;

export const conversationTagsDefinition = `{
    id
    conversation {
        id
    }
    contentTypeAllowedTag ${contentTypeAllowedTagDefinition}
}`;

export const userDefinition = `{
    firstName
    lastName
    email
}`;

export const senderBotUserDefinition = `{
    name
}`;

export const conversationSummaryDefinition = `{
    id
    summary
}`;

export const conversationMessageStatusSubscriptionDefinition = `{
    id
    created
    status
    failedErrorCode
    failedErrorMessage
}`;

export const agentAssignmentContextDefinition = `{
    id
    assignmentDueToBusyAgent ${agentDefinition}
}`;

/* Agent definition used for mentions */
export const mentionAgentDefinition = `{
    id
    isActive
    user {
      firstName
      lastName
      email
    }
    groups {
      id
      name
    }
    agentCurrentStatus {
      canChat
      canVideoCall
      canVoiceCall
      status {
        id
        systemStatus
      }
    }
}`;

export const SHARED_CONTENT_TYPE_ALLOWED_TAG_FRAGMENT = gql`
  fragment SharedContentTypeAllowedTagFragment on ContentTypeAllowedTagObject ${contentTypeAllowedTagDefinition}
`;

export const CONVERSATION_MESSAGE_STATUS_UPDATE_FRAGMENT = gql`
  fragment ConversationMessageStatusUpdate on ConversationMessageObject ${conversationMessageStatusSubscriptionDefinition}
`;
