import { gql } from "@apollo/client";

import { videoSessionDefinition } from "@/definitions/videoCallDefinitions";
import {
  conversationCreatedSourceEnum,
  conversationEventTypeEnum,
} from "@/enums/conversation";
import { voiceCallEventTypeEnum } from "@/enums/voiceCall";
import { conversationEventDefinition } from "./event/conversationEventDefinitions";
import { sharedCurrentParticipantsDefinition } from "./lastVoiceConversation/lastVoiceConversationDefinition";
import * as sharedConversationDefinitions from "./sharedConversationDefinitions";

/* Only applies to unassigned inbox view to exclude message blast conversations when shouldExcludeBlastConversations is true */
export const blastConversationCreatedSources = [
  conversationCreatedSourceEnum.MESSAGE_BLAST,
  conversationCreatedSourceEnum.TEST_MESSAGE_BLAST,
];

/*
  Only applies to conversation list, chat panel, past conversation 
  and CRM chat event summary (should be identical to events timeline).
*/
export const conversationEventsExclusionArray = [
  conversationEventTypeEnum.conversationSummaryGenerated,
  conversationEventTypeEnum.conversationUnreadSet,
  conversationEventTypeEnum.conversationUnreadUnset,
  voiceCallEventTypeEnum.VOICE_AGENT_IP_CALL,
  voiceCallEventTypeEnum.VOICE_AGENT_IP_CALL_MISSED,
  voiceCallEventTypeEnum.VOICE_CALL_ANSWERED,
  voiceCallEventTypeEnum.VOICE_CALL_UNANSWERED,
  voiceCallEventTypeEnum.VOICE_CALL_COMPLETED,
  voiceCallEventTypeEnum.VOICE_CALL_RECORDING_PLAYBACK_READY,
  voiceCallEventTypeEnum.VOICE_CALL_CHANNEL_RECORDING_TRANSCRIBED,
  voiceCallEventTypeEnum.VOICE_CALL_TRANSCRIPTION_COMPLETED,
  voiceCallEventTypeEnum.VOICE_CALL_TRANSLATION_COMPLETED,
];

export const timelineEventsExclusionArray = [
  conversationEventTypeEnum.message,
  conversationEventTypeEnum.fallbackMessage,
  voiceCallEventTypeEnum.VOICE_CALL_INTERACTION,
  ...conversationEventsExclusionArray,
];

/* 
  This definition for a conversation is specifically for the conversation lists in:
    - ConversationPane
    - PastConversationsSection

  Only add fields that will be used by these components
*/
export const conversationListConversationFields = `
  id
  created
  createdSource
  isPriority
  resolvedAt
  conversationStatus: status
  globalUnreadConversationMessagesCount
  allowedInboxView ${sharedConversationDefinitions.allowedInboxViewDefinition}
  assignee ${sharedConversationDefinitions.agentDefinition}
  group ${sharedConversationDefinitions.groupDefinition}
  messagingProviderContact ${sharedConversationDefinitions.messagingProviderContactDefinition}
  conversationTags ${sharedConversationDefinitions.conversationTagsDefinition}
  ongoingVideoSession ${videoSessionDefinition}
  events(
    orderBy: "-created", 
    offset: 0, 
    limit: 1,
    eventTypeEx: [${conversationEventsExclusionArray}]
    ) {
      offset
      totalCount
      results ${conversationEventDefinition}
    }
  contact ${sharedConversationDefinitions.contactDefinition}
  conversationSummary ${sharedConversationDefinitions.conversationSummaryDefinition}
  contactWaitingFromTimestamp
  messagingProviderAccount {
    id
    name
    messagingProvider {
      id
      name
    }
  }
  conversationDispositionValues {
    id
  }
`;

/* The fields in lastVoiceConversation definition must all be present in lastVoiceConversationDefinition. */
export const conversationListConversationDefinition = `{
  ${conversationListConversationFields}
  lastVoiceConversation {
    id
    answeredAt
    voiceCallStatus: conversationStatus
    voiceProviderContact ${sharedConversationDefinitions.voiceProviderContactDefinition}
    currentParticipants {${sharedCurrentParticipantsDefinition}}
    lastVoiceLegOfMe {
      id
      legType
    }
  }
}`;

export const allowedInboxViewsSpecialsCountDefinition = `{
  me
  all
  new
  priority
  unattended
  mention
}`;

export const NEW_CONVERSATION_LIST_ITEM_FRAGMENT = gql`
  fragment NewConversation on ConversationObject ${conversationListConversationDefinition}
`;

export const CACHED_CONVERSATION_LIST_ITEM_FRAGMENT = gql`
  fragment CachedConversation on ConversationObject ${conversationListConversationDefinition}
`;

export const CONVERSATION_ASSIGNMENT_FRAGMENT = gql`
  fragment ConversationAssignment on ConversationObject {
    assignee ${sharedConversationDefinitions.agentDefinition}
  }
`;
