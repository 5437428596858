const parsePhoneNumberErrorEnum = Object.freeze({
  TOO_SHORT: "TOO_SHORT",
  TOO_LONG: "TOO_LONG",
  INVALID_COUNTRY: "INVALID_COUNTRY",
  NOT_A_NUMBER: "NOT_A_NUMBER",
});

export const parsePhoneNumberErrorMessage = Object.freeze({
  [parsePhoneNumberErrorEnum.TOO_LONG]: "Invalid phone number length",
  [parsePhoneNumberErrorEnum.TOO_SHORT]: "Invalid phone number length",
  [parsePhoneNumberErrorEnum.INVALID_COUNTRY]:
    "Phone numbers must start with country code eg. +65",
  [parsePhoneNumberErrorEnum.NOT_A_NUMBER]: "Phone number not detected",
});

export default parsePhoneNumberErrorEnum;
