import { SubscriptionClient } from "subscriptions-transport-ws";

import { getServerSettings } from "@/utils/serverUtils";

export const createSubscriptionClient = ({
  accessToken,
  connectCallback,
  disconnectCallback,
  reconnectingCallback,
  reconnectedCallback,
}) => {
  if (!accessToken) return null;

  let ack = false;
  const { websocketUrl } = getServerSettings();

  /* Create subscription client */
  const subscriptionClient = new SubscriptionClient(websocketUrl, {
    reconnect: true,
    connectionParams: { authToken: `Bearer ${accessToken}` },
    timeout: 75000,
    minTimeout: 10000,
    connectionCallback: () => (ack = true),
  });

  subscriptionClient.use([
    {
      applyMiddleware(options, next) {
        /* Wait for ack to be true before attempting any operation */
        if (!ack) throw new Error("not ready");
        next();
      },
    },
  ]);

  subscriptionClient.client.onmessage = ({ data }) => {
    const { type } = JSON.parse(data) || {};

    /* Let backend know user is still online */
    if (type === "ka") return subscriptionClient.sendMessage(undefined, "ka");

    /* Process message as normal */
    subscriptionClient.processReceivedData(data);
  };

  subscriptionClient.onConnected(() => {
    ack = true;
    connectCallback({ subscriptionClient });
  });

  subscriptionClient.onDisconnected(() => {
    ack = false;
    disconnectCallback({ subscriptionClient });
  });

  subscriptionClient.onReconnecting(() => {
    reconnectingCallback({ subscriptionClient });
  });

  subscriptionClient.onReconnected(() => {
    reconnectedCallback({ subscriptionClient });
  });

  return subscriptionClient;
};
