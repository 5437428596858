import { convertToRaw } from "draft-js";
import { isValidPhoneNumber } from "libphonenumber-js/max";
import { isEmpty } from "lodash";
import isURL from "validator/lib/isURL";

import EditorHelper from "@/components/RichTextEditor/EditorHelper";

/* Validates a string has at least one non space char*/
export const validateNonEmptyString = (value, errorReturn = false) => {
  if (!value) return errorReturn;
  if (value.match(/\S/)) return true;
  return errorReturn;
};

/* Validates a string as an email */
export const validateEmail = (value, errorReturn = false) => {
  if (!value) return errorReturn;
  if (value.match(/^(.*?)@(.*?)\.(.*?)$/g)) return true;
  return errorReturn;
};

/* Validates a string has no space chars */
export const validateNoSpace = (value, errorReturn = false) => {
  if (value.match(/\s/g)) return errorReturn;
  return true;
};

/* Validates the min length of a string */
export const validateMinStringLength = (value, limit, errorReturn = false) => {
  if (value.length < limit) return errorReturn;
  return true;
};

/* Validates the max length of a string */
export const validateMaxStringLength = (value, limit, errorReturn = false) => {
  if (value.length > limit) return errorReturn;
  return true;
};

/* Validates a string as a phone number */
export const validatePhoneNumber = (value, errorReturn = false) => {
  if (!value) return errorReturn;
  if (isValidPhoneNumber(value)) return true;
  return errorReturn;
};

/* Validates an editor has content */
export const validateRichTextEditor = (value, errorReturn = false) => {
  if (!value) return errorReturn;
  const editorHelper = new EditorHelper({ editor: value });
  if (editorHelper.hasContent) return true;

  return errorReturn;
};

/* Validates a select value as being non empty*/
export const validateNonEmptyValue = (value, errorReturn = false) => {
  if (isEmpty(value)) return errorReturn;
  return true;
};

/* Validates the wysiwyg rich text editor has content */
export const validateNonEmptyRichTextEditorWysiwyg = (
  value,
  errorReturn = false,
) => {
  if (!value) return errorReturn;

  const blockArray = convertToRaw(value.getCurrentContent()).blocks;
  const isValid = blockArray.some((block) => {
    const hasText = validateNonEmptyString(block.text);

    if (hasText) return true;
    return false;
  });

  if (!isValid) return errorReturn;
  return true;
};

/* Validates string is a valid url */
export const validateUrlString = (value, errorReturn = false) => {
  if (isEmpty(value)) return errorReturn;

  const options = {
    protocols: ["https"],
    require_protocol: true,
    disallow_auth: true,
  };

  const isValidUrl = isURL(value, options);
  if (!isValidUrl) return errorReturn;
  return true;
};
